import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { lerp } from './uitls';
import ButtonGlow from './ButtonGlow';
import ScrollAnim from './ScrollAnim';

import TextTranslate from './TextTranslate';

export default class PG_Index {
    constructor(app) {
        this.app = app;
        gsap.registerPlugin(ScrollTrigger);

        this.scrollAnim = new ScrollAnim()
        this.container = document.querySelector('.item-container')

        this.TEXTANIM_TITLE = new TextTranslate(document.querySelector('.layer-title .title'), 'words', 'transform');
        this.TEXTANIM_SUB = new TextTranslate(document.querySelector('.layer-title .sub'), 'lines', 'fade');

        this._count = 0;


        this.isGridView = false;
        this.isInit = false;
        this.elementsState = [];

        this.init()

    
    }

    init() {
        console.log('init : PAGE_Index ', this.app)
        this.getDATA()

        new ButtonGlow()

        gsap.delayedCall(0.4, () => {
            this.intro()
        })
    }


    intro() {
        this.TEXTANIM_TITLE.appear(0)
        this.TEXTANIM_SUB.appear(0, 0.2)
    }


    updateDATA() {
        if (this.next) {
            console.log(this.next)
            this.getDATA(this.next)
        } else {
            this.obeserver.disable(true)
        }
    }

    async getDATA(url) {
        try {
            const respose = await this.app.getImages(url)

            const { results, next } = respose.data;

 

            if (results) {

                results.map(r => {
                    this.createItem(r)
                })
    
                function replaceNumberWithPlaceholder(url) {
                    return url.replace(/\/accounts\/\d+\//, `/accounts/\${id}/`);
                }
                
                if (results[0].created_by) {
                    // 사용 예시
                    const originalUrl = results[0].created_by.user_profile_url;

                    const updatedUrl = replaceNumberWithPlaceholder(originalUrl);
                    this.app.accountApi = updatedUrl;
                }
 
            }

            if (next) {
                this.next = next.split('com/api')[1];
            } else {
                this.next = null;
            }

            console.log(this.scrollAnim)
            this.scrollAnim.scrollTransition()
            this.scrollObserver()
        } catch(error) {
            console.log(error)
        }
    }
    createItem(data) {

        const { id, generative_image, total_reaction_count, recent_reactions, created_by } = data;

        let created = {
            name: 'username',
            team_name: 'team_name'
        }

        if (data.created_by) {

            const { name, team_name } = data.created_by;

            created.name = name;
            created.team_name = team_name;
            
        }

        const content = document.createElement('div');
        content.classList.add('item-image')
        // content.classList.add('crop')

        content.style.setProperty('--opacity-value', this.opacityValue);

        content.innerHTML = `
            <div class="thumb">
                <img src=${generative_image} alt="${id}">
            </div>
            <div class="information-deck">
                <div class="tag tag-reaction">
                    <div class="tag-leading tag-symbols"></div>
                    <div class="tag-trailing tag-count"></div>
                </div>
                <div class="tag tag-userinfo">
                    <span class="tag-leading">
                        <span class="tag-text tag-name">${created.name}</span>
                    </span>
                    <div class="tag-trailing">
                        <span class="tag-text tag-team">${created.team_name}</span>
                    </div>
                </div>
            </div>
            <a class="hidden-link" href="/image?id=${id}"></a>
        `;

        if (recent_reactions.length > 0) {
            content.querySelector('.tag-symbols').innerHTML = 
                `${recent_reactions.map((reaction, i) => `
                    <span class="symbol"><img src="${reaction.active_icon}" /></span>`
                )
                .join('')}`
        
            content.querySelector('.tag-count').innerHTML = `<span class="tag-text">${total_reaction_count}</span>`
        } else {
      
            content.querySelector('.tag-symbols').innerHTML = `<span class="symbol"><img src="/assets/icon/icon-reaction-empty.svg" /></span>`
        }

        


        content.addEventListener('click', this._initClick)

        content.querySelector('img').onload = function(){
            gsap.to(content.querySelector('img'), { opacity: 1, duration: 0.8, ease: 'power1.inOut' })
        };
        this.scrollAnim.initElementState()
        this.container.appendChild(content);
    }


    scrollObserver() {
        if (this.obeserver) {
            this.obeserver.refresh();
            this._count = this._count + 1;
            return;
        }
        this.obeserver = ScrollTrigger.create({
            // scroller: '.app',
            trigger: '.item-container',
            start: () => `85% bottom`,
            markers: false,
            onEnter: () => {
                console.log('load more', this._count)
                this.updateDATA()
                // gsap.set(document.body, { opacity: 0s.7 })
            }
        })
    }
   
   

    
}