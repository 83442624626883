import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
// import { lerp } from './uitls';
import ButtonScaleAnim from './ButtonScaleAnim';
import ImageUI from './ImageUI';

export default class PG_Image {
    constructor(app) {
        this.app = app;
        gsap.registerPlugin(ScrollTrigger);

        const url = window.location.search;
        const params = new URLSearchParams(url);
        const id = params.get("id");
        if (id) {
            this.id = id;
        } else {
            window.location.href = '/'
        }

        this.container = document.querySelector('.layer-detail')

        this.buttonReactions = []

        this.isInit = false;
        this.init()
    }

    init() {
        console.log('init : PAGE_Index ', this.app)
        if (this.id) {
            this.getDATA()
        }
    }

    async getDATA() {
        try {
            const response = await this.app.getDetailData(this.id)

            if (response) {
                if (!this.isInit) {
                    this.createItem(response)
                    this.isInit = true;
                } else {
                    this.updateReaction(response.reaction_sheet)
                }
                
            }
        } catch(error) {
            console.log(error)
        }
    }

    updateReaction(reaction_sheet) {
        reaction_sheet.forEach((r, i) => {
            this.buttonReactions[i].count(r.total_reaction_count)
        })
    }
    createItem(data) {

        const { generative_image, reaction_sheet, created_by, id } = data;

        console.log(data)
 
        const created = {
            id: '',
            name: '', 
            team_name: '',
            user_profile_url: ''
        }

        if (created_by) {
            Object.entries(created_by).forEach(([key, value]) => {
                if (key in created) {
                    created[key] = value;
                }
            });
        }


        const content = document.createElement('div');
        content.classList.add('layer-inner')

        content.style.setProperty('--opacity-value', this.opacityValue);

        
        content.innerHTML = `     
           <div class="item-image">
                <div class="thumb">
                    <img src="${generative_image}" alt="">
                </div>

                <div class="reaction-container"></div>
            </div>
        
            <a class="tag" href="/account?id=${created.id}" target="_self">
                <span class="tag-leading">
                    <span class="tag-text tag-name">${created.name}</span>
                </span>
                <span class="tag-trailing">
                    <span class="tag-text tag-team">${created.team_name}</span>
                </span>
            </a>
        `;



        if (reaction_sheet) {
            const reactionContainer = content.querySelector('.reaction-container');


            console.log(reactionContainer)
            reaction_sheet.map((react, i) => {
                const button = document.createElement('button');

                button.classList.add('button-reaction');
                button.innerHTML = `
                    <span class="background"></span>
                    <span class="background-gradient" style="background-image: url('/assets/reactions/Reaction-Sticker-${i}.jpg')"></span>
                    <span class="shadow"></span>
                    <span class="symbol" style="background-image: url('/assets/reactions/Reaction-Icons-${i}.png')"></span>
                    <span class="count text-container">
                        <span class="text-content">${react.total_reaction_count}</span>
                        <span class="text-content">2번째</span>
                    </span>
                `
                const _buttonAnim = new ButtonScaleAnim(button);
                this.buttonReactions.push(_buttonAnim);


                if (react.user_reactions.is_reaction) {
                    button.classList.add('selected');
                    button.setAttribute('data-id', react.user_reactions.id)
                    
                    gsap.delayedCall(i * 0.08, () => {
                        this.buttonReactions[i].introSelect()
                    })
                } else {
                    this.buttonReactions[i].introText()
                }
                reactionContainer.appendChild(button);

                button.addEventListener('click', async () => {
                    console.log('clic')
                    try {
                        if (button.classList.contains('selected')) {
                            // 비동기로 반응 삭제 처리
                            const response = await this.app.deleteReaction(button.getAttribute('data-id'));
                            
                            if (response) { // 삭제가 성공했을 경우만 deselect 실행
                                this.buttonReactions[i].deselect();
                                button.classList.remove('selected')
                                button.removeAttribute('data-id');
                                this.getDATA()
                                // dispatch({ type: 'REMOVE_REACTION', payload: { id: react.id } }); // 상태 업데이트
                            }
                        } else {
                            // 비동기로 반응 생성 처리
                            button.getAttribute('data-id')
                            const response = await this.app.createReaction(id, react.id);
                            
                            if (response) { // 생성이 성공했을 경우만 select 실행
                                this.buttonReactions[i].select();
                                button.setAttribute('data-id', response.id)
                                button.classList.add('selected')
                                this.getDATA()
                            }
                        }
                
                        console.log('click react : ', react.name);
                    } catch (error) {
                        console.error('Reaction handling failed:', error);
                        // dispatch({ type: 'REACTION_ERROR', payload: { error } }); // 에러 처리
                    }
                });                
            })
        }

        // content.addEventListener('click', this._initClick)

        content.querySelector('img').onload = function(){
            //이미지가 로드 된 후 실행할 코드
            gsap.to(content.querySelector('img'), { opacity: 1, duration: 0.8, ease: 'power1.inOut' })
        };

        this.container.appendChild(content);
        this.initImageEvents(this.container.querySelector('.thumb img'))
    }


    initImageEvents(image) {

        this.UI = new ImageUI(this.container);
        let pressTimer; // Long press timer
        const longPressDuration = 500; // 500ms threshold for a long press
    
   
        // 클릭 시 스케일 트랜지션
        const handleClick = () => {
            image.classList.add("scaled");
            setTimeout(() => {
                image.classList.remove("scaled");
            }, 300); // 트랜지션 시간이 끝난 후 원래 상태로 복구

            this.UI.transition()
            
        };
        
        // 꾹 누르면 사진 저장
        const handleLongPress = () => {
            const link = document.createElement("a");
            link.href = image.src; // 이미지 URL
            link.download = "saved-image.jpg"; // 저장될 파일 이름
            // link.click();
        };
        
        // 마우스 버튼을 눌렀을 때
        image.addEventListener("mousedown", () => {
            pressTimer = setTimeout(handleLongPress, longPressDuration);
        });
        
        // 마우스 버튼을 떼거나 화면에서 나갔을 때
        image.addEventListener("mouseup", () => {
            clearTimeout(pressTimer); // 타이머 중지
        });
        
        image.addEventListener("mouseleave", () => {
            clearTimeout(pressTimer); // 타이머 중지
        });
        
        // 클릭 이벤트
        image.addEventListener("click", handleClick);

    }
}