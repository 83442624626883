import gsap from 'gsap';

export default class ButtonGlow {
    constructor() {

        this.init()
    }

    init() {

        const button = document.querySelector('.effect-glow');


        // gsap.utils.toArray('.glow-effect span', button).forEach(glow => {
        //     gsap.to(glow, { rotate: '-180deg', duration: 10, repeat: -1 })
        // })
    }
}