import gsap from 'gsap';
import TextTranslate from './TextTranslate';

export default class GenerateUI {
    constructor(parent) {
        this.parent = parent;
        
        this.layer = document.querySelector('.layer-game')

        this.progress = 'init'
        gsap.delayedCall(1, () => {
            this.init();

        })
    }

    init() {
        this.TEXTANIM_TITLE = new TextTranslate(this.layer.querySelector('.layer-title .title'), 'words', 'transform')
        this.TEXTANIM_SUB = new TextTranslate(this.layer.querySelector('.layer-title .sub'), 'lines')
        // this.TEXT_ANIM = new TextTranslate(this.layer.querySelector('.title'))


        this.SHARE_TEXTANIM_TITLE = new TextTranslate(document.querySelector('.layer[data-type="Share"] .layer-title .title'), 'words', 'transform')
        this.SHARE_TEXTANIM_SUB = new TextTranslate(document.querySelector('.layer[data-type="Share"] .layer-title .sub'), 'lines')
    }


    progress_Onboard(state, _callback) {
        this.progress = 'Onboard'
        const layer = document.querySelector('.layer[data-type="Onboard"]')
        const content = layer.querySelector('.layer-container');
        const background = layer.querySelector('.background')
        const video = layer.querySelector('video')

        const toggleVideo = (state) => {
            if (state === 'play') {
                if (video.paused) {
                    video.play()
                    content.classList.add('is-playing')
                }
            } else if (state === 'pause') {
                if (!video.paused) {
                    video.pause()
                    content.classList.remove('is-playing')
                }
            }
        }


        // Show : 온보딩
        if (state === 'appear') {
            gsap.set(layer, { display: 'flex', pointerEvents: 'auto' })
            layer.classList.add('is-selected');
    
            gsap.to(background, { 
                autoAlpha: 1, 
                duration: 0.25,
                ease: 'power1.inOut'
            })
            gsap.to(content, { 
                autoAlpha: 1, 
                duration: 0.3,
                ease: 'power1.inOut',
                delay: 0.2,
                onComplete: () => {
                    toggleVideo('play')
                }
            })

            // Hidden : 온보딩
        } else if (state === 'disappear') {
            gsap.set(layer, { pointerEvents: 'none' })
            layer.classList.remove('is-selected');
    
            gsap.to(background, { 
                autoAlpha: 0, 
                duration: 0.3,
                ease: 'power1.inOut'
            })
            gsap.to(content, { 
                autoAlpha: 0, 
                duration: 0.3,
                ease: 'power1.inOut',
                delay: 0.0,
                onComplete: () => {
                    gsap.set(layer, { display: 'none', onComplete: () => {
                        gsap.set([layer, content, background], { clearProps: 'all' })
                    } })
                    toggleVideo('pause');
                    if (_callback) _callback()
                }
            })
        }

    }

    progress_Crop(state, _callback) {
        const layer = document.querySelector('.layer[data-type="Crop"]');
        const content = layer.querySelector('.layer-container');
        this.progress = 'Crop'

        // Show : 온보딩
        if (state === 'appear') {
            if (layer.classList.contains('is-selected')) return;
            gsap.set(layer, { display: 'flex', pointerEvents: 'auto', autoAlpha: 1 })
            layer.classList.add('is-selected');

            gsap.to(content, { 
                autoAlpha: 1, 
                duration: 0.3,
                ease: 'power1.inOut',
                delay: 0.0,
            })

            // Hidden : 온보딩
        } else if (state === 'disappear') {
            gsap.set(layer, { pointerEvents: 'none' })
            layer.classList.remove('is-selected');
    
            gsap.to(content, { 
                autoAlpha: 0, 
                duration: 0.3,
                ease: 'power1.inOut',
                delay: 0.0,
                onComplete: () => {
                    gsap.set(layer, { display: 'none', onComplete: () => {
                        gsap.set([layer, content], { clearProps: 'all' })
                    } })
          
                    if (_callback) _callback()
                }
            })
        }
    }

    progress_Game(state, _callback) {
        console.log('progress_Game')
        this.progress = 'Game';
        const layer = document.querySelector('.layer[data-type="Game"]')
        const background = layer.querySelector('.background')
        const content = layer.querySelector('.layer-container');

        // Show : 온보딩
        if (state === 'appear') {
            if (layer.classList.contains('is-selected')) return;
            gsap.set(layer, { display: 'flex', pointerEvents: 'auto', autoAlpha: 1 })
            layer.classList.add('is-selected');
            gsap.to(background, { 
                autoAlpha: 1, 
                duration: 0.25,
                ease: 'power1.inOut'
            })
            
            gsap.to(content, { 
                autoAlpha: 1, 
                duration: 0.3,
                ease: 'power1.inOut',
                delay: 0.2,
                onComplete: () => {
                    this.TEXTANIM_TITLE.appear()
                    this.TEXTANIM_SUB.appear(null, 0.3)

                    // gsap.delayedCall(2, () => {
                    //     this.TEXTANIM_TITLE.disappear(0)
                    //     this.TEXTANIM_SUB.disappear(0, 0.3)

                    //     this.TEXTANIM_TITLE.appear(1)
                    //     this.TEXTANIM_SUB.appear(1, 0.3)
                    // })

                    this.progress_GameStart('appear')
                }
            })

            // Hidden : 온보딩
        } else if (state === 'disappear') {
            gsap.set(layer, { pointerEvents: 'none' })
            layer.classList.remove('is-selected');
    
            gsap.to(content, { 
                autoAlpha: 0, 
                duration: 0.3,
                ease: 'power1.inOut',
                delay: 0.0,
                onComplete: () => {
                    gsap.set(layer, { display: 'none', onComplete: () => {
                        gsap.set([layer, content, background], { clearProps: 'all' })
                    } })
            
                    if (_callback) _callback()
                }
            })
        }
    }

    progress_GameStart(state, _callback) {
        this.progress = 'GameStart'
        const layer = document.querySelector('.layer[data-type="Game"]')
        const content = layer.querySelector('.ui-inner.start');

        // Show : 온보딩
        if (state === 'appear') {
            gsap.set(content, { display: 'flex', pointerEvents: 'auto' })

            gsap.to(content, { 
                autoAlpha: 1, 
                duration: 0.3,
                ease: 'power1.inOut',
                delay: 0.0,
            })

            // Hidden : 온보딩
        } else if (state === 'disappear') {
            gsap.set(layer, { pointerEvents: 'none' })
            layer.classList.remove('is-selected');
    
            gsap.to(layer, { 
                autoAlpha: 0, 
                duration: 0.3,
                ease: 'power1.inOut',
                delay: 0.0,
                onComplete: () => {
                    gsap.set(layer, { display: 'none', onComplete: () => {
                        gsap.set([layer, content], { clearProps: 'all' })
                    } })
                    if (_callback) _callback()
                }
            })
        }
    }

    progress_Notification_Complete_Replicate(url) {
        this.progress = 'Notification_Complete'
        const image = document.createElement('img');
        image.src = url;
        this.layer.querySelector('.item-image.crop .thumb').appendChild(image);

        const layer = document.querySelector('.floating-continue');
        gsap.set(layer, { display: 'block' })
        gsap.to(layer, { 
            autoAlpha: 1, 
            duration: 0.3,
            ease: 'power1.inOut',
            delay: 0.0
        })

        this.TEXTANIM_TITLE.disappear(0)
        this.TEXTANIM_SUB.disappear(0, 0.4)
        this.TEXTANIM_TITLE.appear(1)
        this.TEXTANIM_SUB.appear(1, 0.4)
    }

    appearSwiper() {
        const swipers = document.querySelectorAll('.layer[data-type="SelectionFrame"] .swiper-slide');

        gsap.to(swipers, { opacity: 1, duration: 0.4, ease: 'power1.inOut', stagger: 0.08 })

    }

    progress_SelectionFrame(state, _callback) {
        const layer = document.querySelector('.layer[data-type="SelectionFrame"]');
        const content = layer.querySelector('.layer-container');


        // Show : 온보딩
        if (state === 'appear') {
            if (layer.classList.contains('is-selected')) return;
            gsap.set(layer, { display: 'flex', pointerEvents: 'auto', autoAlpha: 1 })
            layer.classList.add('is-selected');

            gsap.to(content, { 
                autoAlpha: 1, 
                duration: 0.3,
                ease: 'power1.inOut',
                delay: 0.0,
                onComplete: () => {
                    if (_callback) {
                        _callback()
                    }
                }
            })

            // Hidden : 온보딩
        } else if (state === 'disappear') {
            gsap.set(layer, { pointerEvents: 'none' })
            layer.classList.remove('is-selected');
    
            gsap.to(content, { 
                autoAlpha: 0, 
                duration: 0.3,
                ease: 'power1.inOut',
                delay: 0.0,
                onComplete: () => {
                    gsap.set(layer, { display: 'none', onComplete: () => {
                        gsap.set([layer, content], { clearProps: 'all' })
                    } })
          
                    if (_callback) _callback()
                }
            })
        }

    }

    progress_GameHidden(_callback) {
        this.progress = 'Take';
        gsap.to('.game-elements', { 
            autoAlpha: 0,
            display: 'none',
            opacity: 0.3,
            ease: 'power1.inOut',
            onComplete: () => {
                gsap.set('.game-elements', { 
                    display: 'none',
                    onComplete: () => {
                        if (_callback) _callback()
                    }
                })
            }
        })
        const prevFloating = this.layer.querySelector('.button-continue');
        const nextFloating = this.layer.querySelector('.next-floating');

        gsap.to(prevFloating, { 
            autoAlpha: 0,
            opacity: 0.3,
            ease: 'power1.inOut',
            onComplete: () => {
                gsap.set(prevFloating, { 
                    display: 'none',
                    onComplete: () => {
                        gsap.set(nextFloating, { display: 'flex' })
                        gsap.to(nextFloating, {
                            autoAlpha: 1, 
                            duration: 0.3,
                            ease: 'power1.inOut',
                            delay: 0.0,
                            onComplete: () => {
                                gsap.set(nextFloating, { pointerEvents: 'auto' })
                            }
                        })
                    }
                })
            }
        })
    }

    progress_GameVisible(_callback) {

        this.progress = 'Game';

        this.TEXTANIM_TITLE.disappear(2)
        this.TEXTANIM_SUB.disappear(2, 0.4)
        this.TEXTANIM_TITLE.appear(0)
        this.TEXTANIM_SUB.appear(0, 0.4)

        gsap.to('.game-elements', { 
            autoAlpha: 1,
            display: 'flex',
            opacity: 0.3,
            ease: 'power1.inOut',
            onComplete: () => {
                gsap.set('.game-elements', { 
                    pointerEvents: 'auto',
                    onComplete: () => {
                        if (_callback) _callback()
                    }
                })
            }
        })
        const prevFloating = this.layer.querySelector('.button-continue');
        const nextFloating = this.layer.querySelector('.next-floating');

        gsap.to(nextFloating, { 
            autoAlpha: 0,
            opacity: 0.3,
            ease: 'power1.inOut',
            onComplete: () => {
                gsap.set(nextFloating, { 
                    display: 'none',
                })
            }
        })
    }

    progress_TakeImage(state, _callback) {

        this.TEXTANIM_TITLE.disappear(1)
        this.TEXTANIM_SUB.disappear(1, 0.4)
        this.TEXTANIM_TITLE.appear(2)
        this.TEXTANIM_SUB.appear(2, 0.4)
        const layer = this.layer.querySelector('.item-image.crop');


        if (state == 'appear') {

            const callback = () => {
                gsap.set(layer, { display: 'block' })
                
                gsap.to(layer, { 
                    autoAlpha: 1, 
                    duration: 0.3,
                    ease: 'power1.inOut',
                    delay: 0.0,
                    onComplete: () => {
                        if (_callback) {
                            _callback()
                        }
                    }
                })
            }
            this.progress_GameHidden(callback)
        } else if (state == 'disappear') {

            gsap.to(layer, { 
                autoAlpha: 0, 
                duration: 0.3,
                ease: 'power1.inOut',
                delay: 0.0,
                onComplete: () => {
                    gsap.set(layer, { display: 'none', onComplete: () => {
                        if (_callback) {
                            _callback()
                        }
                    }})
                }
            })
        }

        return;
        if (state === 'appear') {


            // Hidden : 온보딩
        } else if (state === 'disappear') {
            gsap.set(layer, { pointerEvents: 'none' })
            layer.classList.remove('is-selected');
    
            gsap.to(layer, { 
                autoAlpha: 0, 
                duration: 0.3,
                ease: 'power1.inOut',
                delay: 0.0,
                onComplete: () => {
                    gsap.set(layer, { display: 'none' })
          
                    if (_callback) _callback()
                }
            })
        }
    }

    progress_Share(state, _callback) {
        const layer = document.querySelector('.layer[data-type="Share"]')
        const content = layer.querySelector('.layer-container');

        if (state === 'appear') {
            if (layer.classList.contains('is-selected')) return;
            gsap.set(layer, { display: 'flex', pointerEvents: 'auto', autoAlpha: 1 })
            layer.classList.add('is-selected');

            gsap.to(content, { 
                autoAlpha: 1, 
                duration: 0.3,
                ease: 'power1.inOut',
                delay: 0.0,
                onComplete: () => {
                    this.SHARE_TEXTANIM_TITLE.appear(0)
                    this.SHARE_TEXTANIM_SUB.appear(0, 0.4)
                    if (_callback) {
                        _callback()
                    }
                }
            })

            // Hidden : 온보딩
        } else if (state === 'disappear') {
            gsap.set(layer, { pointerEvents: 'none' })
            layer.classList.remove('is-selected');
    
            gsap.to(content, { 
                autoAlpha: 0, 
                duration: 0.3,
                ease: 'power1.inOut',
                delay: 0.0,
                onComplete: () => {
                    gsap.set(layer, { display: 'none', onComplete: () => {
                        gsap.set([layer, content], { clearProps: 'all' })
                    } })
          
                    if (_callback) _callback()
                }
            })
        }
    }

    progress_Error(state, _callback) {
        this.error = state == 'appear' ? true : false;
        const layer = document.querySelector('.toast-alert');

        // Show : 온보딩
        if (state === 'appear') {
            if (layer.classList.contains('is-selected')) return;
            gsap.set(layer, { display: 'flex', pointerEvents: 'auto' })
            layer.classList.add('is-selected');

            gsap.to(layer, { 
                autoAlpha: 1, 
                duration: 0.25,
                ease: 'power1.inOut',
                onComplete: () => {
                    if (_callback) _callback()
                }
            })

            // Hidden : 온보딩
        } else if (state === 'disappear') {
            gsap.set(layer, { pointerEvents: 'none' })
            gsap.to(layer, { 
                autoAlpha: 0, 
                duration: 0.3,
                ease: 'power1.inOut',
                delay: 0.0,
                onComplete: () => {
                    gsap.set(layer, { display: 'none', onComplete: () => {
                        gsap.set([layer], { clearProps: 'all' })
                        layer.classList.remove('is-selected');
                    } })
                    
                    if (_callback) _callback()
                }
            })
        }
    }
}