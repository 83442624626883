import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';

export default class CropImage {
    constructor(src, isInit) {
        this.src = src;
        this.isInit = isInit;
        this.cropLayer = document.querySelector('.layer-generate[data-index="0"] .thumb')

        this.buttonCrop = document.querySelector('.button-generate')
        this.init();
    }

    init() {
        const image = document.createElement('img');
        image.src = this.src;
        this.image = image;

        this.cropLayer.appendChild(image);

        const cropper = new Cropper(image, {
            dragMode: 'move',
            aspectRatio: 3 / 4,
            autoCropArea: 1,
            restore: false,
            guides: true,
            center: true, // 크롭 박스가 이미지 중심에 배치
            responsive: true, // 반응형 동작 활성화
            highlight: false,
            cropBoxMovable: false,
            cropBoxResizable: false,
            toggleDragModeOnDblclick: false,
            ready: () => {
                const imageData = this.cropper.getImageData();
                const cropBoxData = {
                    width: imageData.naturalWidth,
                    height: imageData.naturalHeight,
                    left: (imageData.width - imageData.naturalWidth) / 2,
                    top: (imageData.height - imageData.naturalHeight) / 2,
                };
                this.cropper.setCropBoxData(cropBoxData);
            }
        });

        this.cropper = cropper;




        if (!this.isInit) {
            // this.buttonCrop.disabled = false;
        }
        const contData = cropper.getContainerData();
        // console.log(contData)
        cropper.setCropBoxData({ height: contData.height, width: contData.width });
    }

    destroy() {
        this.cropper.destroy();
        this.image.remove();
        this.buttonCrop.disabled = true;
    }
    updateSize() {
        const containerData = this.cropper.getContainerData(); // 이미지 컨테이너 크기 가져오기
  
        // 컨테이너 크기에 맞게 크롭 박스를 리사이징
        const newCropBoxWidth = containerData.width; // 컨테이너의 50% 너비로 설정
        const newCropBoxHeight = newCropBoxWidth * (4 / 3); // 3:4 비율 유지
  
        this.cropper.setCropBoxData({
            width: newCropBoxWidth,
            height: newCropBoxHeight,
            left: (containerData.width - newCropBoxWidth) / 2, // 컨테이너 중앙
            top: (containerData.height - newCropBoxHeight) / 2,
        });
    }
}