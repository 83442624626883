
import './scss/common.css'
import App from './App';
import gsap from 'gsap';

import PG_Index from './PG_Index';
import PG_Generate from './PG_Generate';
import PG_Image from './PG_Image';
import PG_Account from './PG_Account';
import PG_Login from './PG_Login';


function loadPage() {
    const app = new App();
    app.init();
    (async () => {
        await app.init()
        // await app.isLoggedIn()
        app.setupInterceptors();
        setTimeout(() => {
            // PAGE : Index
            if (document.querySelector('.layer-generate')) {
                new PG_Generate(app)
            } else if (document.querySelector('.layer-list.account')) {
                new PG_Account(app)
            } else if (document.querySelector('.layer-list')) {
                new PG_Index(app);
            } else if (document.querySelector('.layer-login')) {
                new PG_Login(app)
            } else {
                new PG_Image(app)
            }

            if (app.isLoggedIn()) {
                gsap.to('.app', { opacity: 1, duration: 0.4, ease: 'power1.inOut' })

            } else {
                gsap.to('.app', { opacity: 1, duration: 0.4, ease: 'power1.inOut' })
            }
        }, 100)
    })();

    window.app = app;
}

window.onload = () => {
    loadPage()
}
