import gsap from 'gsap';

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function numberWithoutCommas(num) {
   return parseFloat(num.replace(/,/g, ''));
}

export function lerp(start, end, t) {
    return start + (end - start) * t;
}

export function animateCounter(target, startValue, endValue, duration) {
    const obj = { value: startValue }; // Initial value object

    gsap.to(obj, {
        value: endValue,
        duration: duration,
        ease: "power1.out", // Easing function
        onUpdate: () => {
            target.innerHTML = Math.round(obj.value); // Update innerHTML with rounded value
        },
    });
}

export function appearAlert(_callback) {

    const layer = document.createElement('div');

    layer.classList.add('layer')
    layer.classList.add('layer-alert')
    layer.innerHTML = `
        <div class="background"></div>
        <div class="alert-container">
            <div class="alert-title">
                <div class="title">삭제</div>
                <div class="sub">이미지를 삭제하시겠습니까?</div>
            </div>
            <div class="alert-buttons">
                <button class="button-alert button-cancel">취소</button>
                <button class="button-alert button-confirm">확인</button>
            </div>
        </div>
    `

    const background = layer.querySelector('.background');
    const container = layer.querySelector('.alert-container');

    document.body.appendChild(layer);

    gsap.set(layer, { display: 'flex' })
    gsap.to(background, { autoAlpha: 1, duration: 0.3, ease: 'power1.inOut' })
    gsap.to(container, { autoAlpha: 1, duration: 0.4, ease: 'power1.inOut', onComplete: () => {
        gsap.set(layer, { pointerEvents: 'auto', onComplete: () => {
            if (_callback) _callback()
        } })
    } })

    return layer;
    
}

export function disappearAlert(layer) {

    const background = layer.querySelector('.background');
    const container = layer.querySelector('.alert-container');

    
    gsap.set(layer, { pointerEvents: 'none' })
    gsap.to(background, { autoAlpha: 0, duration: 0.3, ease: 'power1.inOut' })
    gsap.to(container, { autoAlpha: 0, duration: 0.4, ease: 'power1.inOut', onComplete: () => {
        gsap.set(layer, { display: 'none', onComplete: () => {
         
            layer.remove()
        } })
    } })
}