import gsap from 'gsap';
import SplitText from 'gsap/SplitText';

export default class TextTranslate {
    constructor(container, type, animationtype) {

        gsap.registerPlugin(SplitText)
       
        this.yv = '110%';
        this.textArr = container ? gsap.utils.toArray('.text-content', container) : [];

        this.stagger = 0.08;
       
        this.elements = [];

        this.animationtype = animationtype || 'fade'
        this.textArr.forEach((t, i) => t.setAttribute('data-index', i));
       
        if (type) {
            this.textArr.forEach(t => {
                if (t) {
                    this.elements.push(new SplitText(t, { type: type })[type]);
                }
            });
        } else {
            this.elements = this.textArr;
        }

        this.current = 0;
        

        this.initStyle()
    }

    init() {
        gsap.delayedCall(2, () => {
            this.appear()
        })
    }

    initStyle() {
        if (this.animationtype == 'transform') {

            gsap.set(this.elements, { 
                rotationX: 180, 
                autoAlpha: 0,
                y: this.yv,
                rotationX: 180
            });
        } else {
            gsap.set(this.elements, { 
                autoAlpha: 0,  
                y: this.yv
            });
        }
    }

    appear_transform(elements, delay) {
        gsap.to(elements, {   
            rotationX: 0,
            y: 0,
            scale: 1,
            duration: 0.6, 
            ease: 'power2.out', 
            stagger: this.stagger, 
            delay: delay 
        })
        gsap.to(elements, {   
            autoAlpha: 1,
            duration: 0.6, 
            ease: 'power1.inOut', 
            stagger: this.stagger, 
            delay: delay 
        })
    }

    appear_fade(elements, delay) {
        gsap.to(elements, {   
            autoAlpha: 1,
            duration: 0.6, 
            ease: 'power1.inOut', 
            stagger: this.stagger, 
            delay: delay 
        })

        gsap.to(elements, {   
            y: '0%',
            duration: 0.6, 
            ease: 'power2.out', 
            stagger: this.stagger, 
            delay: delay 
        })
    }

    appear(index, delay) {
        const _index = index || this.current;
        const target = this.elements[_index];
        gsap.set(this.textArr[_index], { autoAlpha: 1 })

        this.textArr.forEach((t, i) => t.setAttribute('data-visible', i == _index))

        if (this.animationtype == 'transform') {
            this.appear_transform(target, delay)
        } else if (this.animationtype == 'fade') {
            this.appear_fade(target, delay)
        }
        
    }

    disappear_transform(elements, delay) {
        gsap.to(elements, {   
            rotationX: 180, 
            autoAlpha: 0,
            y: this.yv,
            duration: 0.6, 
            ease: 'power2.out', 
            stagger: this.stagger, 
            delay: delay 
        })
        gsap.to(elements, {   
            autoAlpha: 0,
            duration: 0.6, 
            ease: 'power1.inOut', 
            stagger: this.stagger, 
            delay: delay 
        })
    }

    disappear_fade(elements, delay) {
        gsap.to(elements, {   
            autoAlpha: 0,
            duration: 0.6, 
            ease: 'power1.inOut', 
            stagger: this.stagger, 
            delay: delay 
        })

        gsap.to(elements, {   
            y: `-${this.yv}%`,
            duration: 0.6, 
            ease: 'power2.out', 
            stagger: this.stagger, 
            delay: delay 
        })

        
    }

    disappear(index, delay) {
        const _index = index || this.current;
        const target = this.elements[_index];

        this.textArr.forEach((t, i) => t.setAttribute('data-visible', i !== _index))

        gsap.set(this.textArr[_index], { autoAlpha: 1 })
        if (this.animationtype == 'transform') {
            this.disappear_transform(target, delay)
        } else if (this.animationtype == 'fade') {
            this.disappear_fade(target, delay)
        }
        
    }
}