import gsap from 'gsap'

export default class ImageUI {
    constructor(layer) {
        this.layer = layer;
        this.buttons = document.querySelectorAll('.button-reaction')
        this.content = this.layer.querySelector('.thumb img')
        this.tag = this.layer.querySelector('.tag')


        this.state = 'in'
        this.initStyle()

    }

    initStyle() {
        this.buttons.forEach((button, index) => {

            let getter = gsap.getProperty(button);
            button.setAttribute('data-top', getter('top', '%'))
            button.setAttribute('data-left', getter('left', '%'))

        })
    }

    transition() {
        gsap.killTweensOf(this.buttons, 'all')
        gsap.killTweensOf(this.content, 'all')
        gsap.killTweensOf(this.tag, 'all')

        if (this.state == 'in') {
            this.outReactionButtons()
        } else {
            this.inReactionButtons()
        }
    }

    outReactionButtons() {
        this.state = 'out'
        const _left = document.querySelector('.thumb').getBoundingClientRect().left;

        const delayButton = 0.0;

        this.buttons.forEach((button, index) => {
            const { left, width } = button.getBoundingClientRect();
        

            const direction = index < 2 ? -1 : 1;
            let targetLeft = (left + width + _left + 10) * direction
        
            gsap.to(button, {
                left: targetLeft,
                duration: 0.6,
                ease: 'power2.out',
                delay: delayButton + (index * 0.06),
            });
        });
        
        gsap.to(this.content, { scale: 1.12, duration: 0.6, ease: 'power2.out', delay: 0.1 })
        gsap.to(this.tag, { autoAlpha: 0, duration: 0.4, ease: 'power1.inOut'})
    }


    inReactionButtons() {
        this.state = 'in'
        this.buttons.forEach((button, index) => {
            gsap.to(button, { 
                top: `${button.getAttribute('data-top')}%`, 
                left: `${button.getAttribute('data-left')}%`, 
                duration: 0.5, 
                ease: 'circ.out',
                delay: (index * 0.1),
            })

        })

        gsap.to(this.content, { scale: 1.0, duration: 0.6, ease: 'back.out', delay: 0 })
        gsap.to(this.tag, { autoAlpha: 1, duration: 0.4, ease: 'power1.inOut', delay: 0 })
    }
}