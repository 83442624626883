import axios from "axios";
import gsap from 'gsap';

const apiClient = axios.create({
    baseURL: 'https://staging.api.event.plus-ex.com/api',
    headers: {
        'Content-Type': 'application/json',
    },
});
window.apiClient = apiClient;

export default class App {
    constructor() {
        this.yepp_accessToken = localStorage.getItem('yepp_accessToken') || null;
        this.yepp_refreshToken = localStorage.getItem('yepp_refreshToken') || null;

        this.id = 0;


        gsap.utils.toArray('.button-share').forEach(button => {
            button.addEventListener('click', () => {
                if (window.navigator) {
                    navigator.share({
                        title: 'YEPP',
                        text: 'YEPP description',
                        url: window.location.href,
                    });
            
                }
            })
        })
    }

    async init() {
        const validSession = await this.validateSession();
        if (validSession) {
            this.setupInterceptors();
            // console.log('Session active. User is logged in.');
            this.getAccountMy()

        } else {
            // console.log('No active session or invalid token. Redirecting to login...');
            this.redirectToLogin();
        }
    }

    // 로그인 여부 확인
    async isLoggedIn() {
        if (!this.yepp_accessToken) return false;
    
        try {
            const response = await axios.post('https://staging.api.event.plus-ex.com/api/accounts/token/verify', { token: this.yepp_accessToken });
            // console.log('is logged in ', response)
            return response.status === 200; // 유효한 토큰일 경우 true 반환
        } catch (error) {
            console.error('Token validation failed:', error);
            return false;
        }
    }

    async getAccountMy() {
        try {
            const response = await apiClient.get('/accounts/me');
            // console.log('getAccountMy ', response)

            gsap.utils.toArray('.button-account').forEach(b => b.href = `/account?id=${response.data.id}`)
            this.id = response.data.id;
            this.show_user_guide = response.data.show_user_guide;
            return response.status === 200;
        } catch (error) {
            console.error('getAccountMy failed:', error);
            return false;
        }
    }
    
    async validateSession() {
        const isValid = await this.isLoggedIn();
        if (!isValid) {
            this.clearTokens(); // 만료된 토큰 삭제
        }
        return isValid;
    }
    
    // 로그인 페이지로 리다이렉트
    redirectToLogin() {
        const currentPath = window.location.pathname; // 현재 경로
        if (currentPath !== '/login/') {
            localStorage.setItem('redirectAfterLogin', currentPath); // 리다이렉트 경로 저장
            window.location.href = '/login/'; // 로그인 페이지로 이동
        }
    }

    // 토큰 저장
    saveTokens(access, refresh) {
        this.yepp_accessToken = access;
        this.yepp_refreshToken = refresh;
        localStorage.setItem('yepp_accessToken', access);
        localStorage.setItem('yepp_refreshToken', refresh);
    }

    // 토큰 삭제
    clearTokens() {
        this.yepp_accessToken = null;
        this.yepp_refreshToken = null;
        localStorage.removeItem('yepp_accessToken');
        localStorage.removeItem('yepp_refreshToken');
    }

    async login(username, password) {
        try {
            const response = await axios.post('https://staging.api.event.plus-ex.com/api/accounts/token', {
                username,
                password,
            });

            const { access, refresh } = response.data;

            this.saveTokens(access, refresh); // 토큰 저장

            this.setupInterceptors(); // 인터셉터 설정


            // 로그인 후 리다이렉트 처리
            const redirectPath = localStorage.getItem('redirectAfterLogin') || '/';
            localStorage.removeItem('redirectAfterLogin'); // 저장된 리다이렉트 경로 제거
            window.location.href = redirectPath; // 기존 경로로 이동
        } catch (error) {
            console.error('Error - login :', error);
            document.querySelector('.error').innerHTML = '아이디 또는 비밀번호를 확인해 주세요.';
            document.querySelector('.error').classList.add('is-error')
        }
    }

    async refreshyepp_accessToken() {
        try {
            const response = await apiClient.post('/accounts/token/refresh', {
                refresh: this.yepp_refreshToken,
            });

            const { access, refresh } = response.data;

            this.saveTokens(access, refresh); // 갱신된 토큰 저장
            // console.log('Access token refreshed:', response.data);
        } catch (error) {
            console.error('Error refreshing token:', error);
            this.logout(); // 실패 시 로그아웃 처리
            this.redirectToLogin(); // 로그인 페이지로 리다이렉트
        }
    }

    logout() {
        // this.clearTokens(); // 세션에서 토큰 제거
        // console.log('Logged out successfully.');
        this.redirectToLogin(); // 로그아웃 후 로그인 페이지로 이동
    }

    setupInterceptors() {
        apiClient.interceptors.request.use((config) => {
            if (this.yepp_accessToken) {
                config.headers.Authorization = `Bearer ${this.yepp_accessToken}`;
                // console.log('Using token:', this.yepp_accessToken);
            }
            return config;
        });

        apiClient.interceptors.response.use(
            (response) => response,
            async (error) => {
                const originalRequest = error.config;

                if (error.response?.status === 401 && !originalRequest._retry) {
                    originalRequest._retry = true;
                    try {
                        await this.refreshyepp_accessToken();
                        originalRequest.headers.Authorization = `Bearer ${this.yepp_accessToken}`;
                        return apiClient(originalRequest);
                    } catch (refreshError) {
                        console.error('Refresh token expired:', refreshError);
                        this.logout();
                        return Promise.reject(refreshError);
                    }
                }

                return Promise.reject(error);
            }
        );
    }

    async getImages(url) {
        try {
            const _url = url || '/generate/images';
            const response = await apiClient.get(_url);

            if (response) {
                // console.log(response);
                return response;
            }
        } catch (error) {
            // console.log(apiClient);
            // console.log('error');
        }
    }

    async getDetailData(id) {
        try {
            const response = await apiClient.get(`/generate/images/${id}`);
            if (response) {
                return response.data;
            }
        } catch (error) {
            // console.log(error);
            window.location.href = '/404.html';
        }
    }

    async replicateImage(image) {
        try {
            const formData = new FormData();
            formData.append('image', image);

            const response = await axios.post(
                `https://staging.api.event.plus-ex.com/api/replicate`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${this.yepp_accessToken}`,
                    },
                }
            );
            // console.log(response, this.yepp_accessToken);
            return response;
        } catch (error) {
            // console.log(error, image);
        }
    }

    async generateImage(init_image, generative_image) {
        try {
            const formData = new FormData();

            formData.append('init_image', init_image);
            formData.append('generative_image', generative_image);

            const response = await axios.post(
                `https://staging.api.event.plus-ex.com/api/generate`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${this.yepp_accessToken}`,
                    },
                }
            );
            // console.log(response, this.yepp_accessToken);
            return response;
        } catch (error) {
            // console.log(error, init_image);
        }
    }

    async createReaction(generate_image, reaction_type) {
        try {
            const response = await apiClient.post(`/reactions`, {
                generate_image,
                reaction_type,
            });
    
            if (response) { // HTTP 200 Created
                return response.data; // 성공 시 true 반환
            }
            console.error('Failed to create reaction:', response.status, response.data);
            return false; // 성공하지 않은 경우 false 반환
        } catch (error) {
        }
    }
    
    async deleteReaction(id) {
        try {
            const response = await apiClient.delete(`/reactions/${id}`);
    
            if (response ) { // HTTP 200 OK
                // console.log('Reaction deleted successfully:', response.data);
                return true; // 성공 시 true 반환
            }
            console.error('Failed to delete reaction:', response.status, response.data);
            return false; // 성공하지 않은 경우 false 반환
        } catch (error) {
        }
    }
    
    async getAccount(id) {
        try {
            const response = await apiClient.get(`/accounts/${id}/profile`);
            if (response) {
                return response.data;
            }
        } catch (error) {
            // console.log(error);
        }
    }

    async deleteImage(id) {
        try {
            const response = await apiClient.delete(`/generate/images/${id}`);
            return response;
        } catch (error) {
            // console.log('error');
        }
    }

    async getCardFrames() {
        try {
            const response = await apiClient.get(`/cardframes`);
            if (response) {
                return response.data;
            }
        } catch (error) {
            // console.log(error);
        }
    }

    async hiddenGuide() {
        try {
            const response = await apiClient.patch(`/accounts/${this.id}/guide`);

            if (response) {
                // console.log('hidden Guide', response.data)
            }
        } catch(error) {
            // console.log('hidden Guide', error)
        }
    }
}
