import gsap from 'gsap';
import TextTranslate from './TextTranslate';
import {
    animateCounter
} from './uitls';
export default class ButtonScaleAnim {
    constructor(selector) {
        this.target = selector;
        this.icon = this.target.querySelector('.symbol')
        this.background = this.target.querySelector('.background-gradient');
        this.shadow = this.target.querySelector('.shadow')

        this.scaleValue = 0.8;
        this.opacityValue = 0.4;

        this.targetScale = 1.2;

        this.text = this.target.querySelector('.text-container');

        this.TEXTANIM = new TextTranslate(this.text, 'lines');
        this.init();

        this.addFloatingEffect()
    }

    init() {
        // 이벤트 리스너 추가
        this.target.addEventListener('mousedown', this.scaleDown.bind(this));
        this.target.addEventListener('mouseup', this.scaleUp.bind(this));
        this.target.addEventListener('mouseleave', this.scaleUp.bind(this));

        this.target.addEventListener('touchstart', (event) => {
            this.scaleDown();
        });
        this.target.addEventListener('touchend', this.scaleUp.bind(this));
        this.target.addEventListener('touchcancel', this.scaleUp.bind(this));
    }


    scaleDown() {

        gsap.to(this.target, {
            scale: this.scaleValue,
            duration: 0.2,
            ease: "power1.out",
        });

        gsap.to(this.shadow, {
            opacity: 1,
            duration: 0.2,
            ease: "power1.out",
        })

        gsap.to(this.icon, {
            rotate: '-10deg',
            duration: 0.2,
            ease: "power1.out",
        });
    }

    scaleUp() {
        gsap.to(this.target, {
            scale: 1,
            duration: 0.2,
            ease: "power1.out",
        });
        gsap.to(this.icon, {
            rotate: '0deg',
            duration: 0.2,
            ease: "power1.out",
        });
        gsap.to(this.shadow, {
            opacity: 0,
            duration: 0.2,
            ease: "back.out",
        })
    }

    select() {
        gsap.killTweensOf(this.shadow, '*')
        gsap.to(this.icon, {
            scale: this.targetScale,
            duration: 0.5,
            ease: "power1.out",
        })
        gsap.to(this.icon, {
            opacity: 1,
            duration: 0.5,
            ease: "power1.out",
        })
        gsap.to(this.background, {
            opacity: 1,
            duration: 0.5,
            ease: "power1.out",
        })

        gsap.to(this.shadow, {
            opacity: 1,
            duration: 0.5,
            ease: "power1.inOut",
        })
    }

    deselect() {
        gsap.killTweensOf(this.shadow, '*')
        gsap.to(this.icon, {
            scale: this.scaleValue,
            duration: 0.5,
            ease: "power1.out",
        })
        gsap.to(this.icon, {
            opacity: this.opacityValue,
            duration: 0.5,
            ease: "power1.out",
        })
        gsap.to(this.background, {
            opacity: 0,
            duration: 0.5,
            ease: "power1.out",
        })
        gsap.to(this.shadow, {
            opacity: 0,
            duration: 0.5,
            ease: "power1.inOut",
        })
    }

    introSelect() {
        gsap.to(this.target, {
            scale: 1,
            duration: 0.5,
            ease: "power2.out",
        })
        gsap.to(this.icon, {
            scale: this.targetScale,
            duration: 0.5,
            ease: "power2.out",
        })
        gsap.to(this.icon, {
            opacity: 1,
            duration: 0.5,
            ease: "power2.out",
        })
        gsap.to(this.background, {
            opacity: 1,
            duration: 0.5,
            ease: "power2.out",
        })
        gsap.to(this.shadow, {
            opacity: 1,
            duration: 0.5,
            ease: "power1.inOut",
        })
        this.TEXTANIM.appear()
    }

    introText() {
        this.TEXTANIM.appear()
    }

    count(num) {

        // console.log('countUP', this.TEXTANIM.textArr[0])

        animateCounter(this.TEXTANIM.textArr[0], this.TEXTANIM.textArr[0].innerHTML, num, Math.floor(Math.random() * 2));

    }

    addFloatingEffect() {
        gsap.to(this.target, {
            y: "+=" + (Math.random() * 30 - 15), // -15px에서 +15px 사이 랜덤 이동
            duration: 2, // 애니메이션 지속 시간
            ease: "power1.inOut",
            repeat: -1, // 무한 반복
            yoyo: true, // 왕복 애니메이션
            delay: Math.random() * 1.5, // 각 요소가 시차를 가짐
          });
      
          gsap.to(this.target, {
            x: "+=" + (Math.random() * 20 - 10), // -10px에서 +10px 사이 랜덤 이동
            duration: 3, // 애니메이션 지속 시간
            ease: "power1.inOut",
            repeat: -1, // 무한 반복
            yoyo: true, // 왕복 애니메이션
            delay: Math.random() * 1.5, // 각 요소가 시차를 가짐
          });
      
          gsap.to(this.target, {
            rotation: "+=" + (Math.random() * 20 - 10), // -10도에서 +10도 사이 랜덤 회전
            duration: 4, // 애니메이션 지속 시간
            ease: "power1.inOut",
            repeat: -1, // 무한 반복
            yoyo: true, // 왕복 애니메이션
            delay: Math.random() * 1.5, // 각 요소가 시차를 가짐
          });
    }
}