import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

export default class ScrollAnim {

    constructor() {
        gsap.registerPlugin(ScrollTrigger);

        this.container = document.querySelector('.item-container')

        this.opacityValue = 0.3;
        this.scaleValue = 0.7;
        this.translateValue = 30;

        this.frameTime = 16;
        this.duration = 500;

        this.isGridView = false;
        this.isInit = false;

        this.elementsState = [];
        this.init()
    }

    init() {
        this.initChangeView()
    }

    initElementState() {
        this.elementsState.push({translateX: this.translateValue, opacity: this.opacityValue, scale: this.scaleValue})
    }

    initChangeView() {
        document.querySelector('.button-grid').addEventListener('click', () => {
            if (!this.container.classList.contains('grid-view')) {
                this.isGridView = true;
                this.container.classList.add('grid-view')
                gsap.to('.item-image', { xPercent: 0, '--opacity-value': 1, duration: 0.2 })
            } else {
                this.isGridView = false;
                this.container.classList.remove('grid-view')
                gsap.delayedCall(0.1, () => {
                    this.updateElements()
                })
            }
        })
    }
    turtleEaseOut(t) {
        return 1 - Math.pow(1 - t, 5);
    }

   
    lerpTurtle(start, end, t) {
        const adjustedT = this.turtleEaseOut(t);
        return start + (end - start) * adjustedT;
    }

    getLerpT() {
        return this.frameTime / this.duration;
    }

    updateElements() {
        gsap.utils.toArray('.item-image', this.container).forEach((box, index) => {
            const rect = box.getBoundingClientRect();
            const boxCenterY = rect.top + rect.height / 2;
            const windowCenter = window.innerHeight / 2;

            const maxDistance = window.innerHeight;
            const distanceFromCenter = Math.abs(boxCenterY - windowCenter);

            let targetTranslateX;
            if (boxCenterY < windowCenter) {
                targetTranslateX = Math.max(-this.translateValue, (boxCenterY - windowCenter) / (maxDistance / 2) * this.translateValue);
            } else {
                targetTranslateX = Math.min(this.translateValue, (boxCenterY - windowCenter) / (maxDistance / 2) * this.translateValue);
            }

           
            const normalizedDistance = distanceFromCenter / (maxDistance / 2);
            const targetOpacity = Math.max(this.opacityValue, 1 - normalizedDistance * (1 - this.opacityValue));
            const targetScale = Math.max(this.scaleValue, 1 - normalizedDistance * (1 - this.scaleValue));

           
            this.elementsState[index].targetTranslateX = targetTranslateX;
            this.elementsState[index].targetOpacity = targetOpacity;
            this.elementsState[index].targetScale = targetScale;
        });
    }

    render() {
        const t = this.getLerpT();

        gsap.utils.toArray('.item-image', this.container).forEach((box, index) => {
            const state = this.elementsState[index];

            if (this.isGridView) {
                box.style.transform = `translateX(0%) scale(1)`;
                box.style.opacity = 1;

                return;
            }

            state.translateX = this.lerpTurtle(state.translateX, state.targetTranslateX, t);
            state.opacity = this.lerpTurtle(state.opacity, state.targetOpacity, t);
            state.scale = this.lerpTurtle(state.scale, state.targetScale, t);

            box.style.transform = `translateX(${state.translateX}%) scale(${state.scale})`;
            box.style.opacity = state.opacity;
        
        });

       
        requestAnimationFrame(() => this.render());
    }

    scrollTransition() {
        this.updateElements();
       
        if (!this.isInit) {
            this.isInit = true;
            window.addEventListener('scroll', () => {
                console.log('scorll')
                if (!this.ticking) {
                    requestAnimationFrame(() => {
                        this.updateElements();
                        this.ticking = false;
                    });
                    this.ticking = true;
                }
            });
            this.render();
        }

    }
}