import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';
import gsap from 'gsap';
import CropImage from './CropImage';
// swiper
import "swiper/css";
import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { domToJpeg } from 'modern-screenshot';

import GenerateUI from './GenerateUI'
import p5 from "p5";
import sketch from './sketch';


export default class PG_Generate {
    constructor(app) {
        // console.log('Init : PG_Generate')
        this.app = app;

        this.resultImage = 'https://replicate.delivery/xezq/DxEQw5pCVIJCA9XJo08DG1sakWDeVeebQB5jJ8QL4Qc6RYynA/out-0.jpg'

        this.frames = [];

        const titles = [
            '24 Rookie',
            'Smile',
            'The Most Valuable Plaver',
            'The Person OF The Year'

        ]

        for (let i = 0; i < 4; i++) {
            this.frames.push({
                name: titles[i],
                card_frame: `/assets/frame/Card-${i + 1}.png`
            })
        }

        // this.getFrames()

        this.backgroundColor = 'rgb(0,0,0)';
        this.layers = gsap.utils.toArray('.layer-generate');

        this.buttonGenerate = gsap.utils.toArray('.button-generate')
        this.buttonTakePhoto = document.querySelector('.button-continue')
        this.buttonContinue = document.querySelector('.button-continue-progress')
        this.buttonPost = document.querySelector('.button-post')
        this.buttonSave = document.querySelector('.button-save');
        this.cropper = null;

        this.current = 0;

        this.imageQuality = 0.8;

        this.UI = new GenerateUI(this);

        this.init()
    }

    init() {
        Swiper.use([Pagination ]);
        this.initInputImage()
        this.initGenerate()

        this.initPost()
        this.initSave()

        this.initContinue()

        // this.generateSelectFrame()
        this.UI.progress_Onboard('appear')

        this.initGame()
        // this.progress_Game('init')
        console.log(this.Game)
    }

    initGame() {
        document.querySelector('.button-game-complete').addEventListener('click', () => {
            this.finishGame()
        })
    }

    initContinue() {
        this.buttonTakePhoto.addEventListener('click', () => {
            this.buttonTakePhoto.disabled = true;
            // console.log('click continue')
            this.finishGame()
        })

        this.buttonContinue.addEventListener('click', () => {
            const _callback = () => {
            
                this.Game.remove()
                const callback = () => this.generateSelectFrame();
                this.UI.progress_SelectionFrame('appear', callback)
            }

            this.UI.progress_Game('disappear', _callback)
        })
    }

    deleteCropImage() {
        // this.cropImage.remove()
        // this.cropImage = null;
    }

    dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[arr.length - 1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
        return new File([u8arr], filename, {type: mime});
    }

    progress_Game(state) {
        
        if (state == 'init') {

            const _callback = () => {
                this.UI.progress_Game('appear')
            }

            this.UI.progress_Crop('disappear', _callback)

            gsap.delayedCall(0.2, () => {
                this.Game = new p5(sketch);
                
            })
        } else if (state == 'continue') {
            this.Game.playLoop()
        }

    }

    finishGame() {

        this.UI.progress_TakeImage('appear')
        // this.UI.progress_Game('disappear', _callback)
    }

    

    testError() {
        this.UI.progress_Error('appear');
        // console.log(' testError : ',this.Game)
        if (this.UI.progress == 'GameStart') {
            // this.UI.progress = 'GameStopped'
            this.Game.stopLoop()
        }
    }

    async initGenerate() {
        this.buttonGenerate.forEach(button => {
            button.addEventListener('click', this.handleGenerateClick.bind(this, button));
        });
    }
    
    async handleGenerateClick(button) {
        if (!this.cropper) {
            // console.warn('Cropper가 초기화되지 않았습니다.');
            return;
        }
    
        try {

            const { error, progress } = this.UI;

            this.buttonGenerate.forEach(button => button.disabled = true)
            if (this.UI.error) {

                this.UI.progress_Error('disappear')

                // this.Game.playLoop()
            }
            // 자른 이미지 데이터를 파일로 변환
            const croppedDataUrl = this.cropper.cropper
                .getCroppedCanvas({ minWidth: 900, minHeight: 1200, quality: this.imageQuality })
                .toDataURL('image/jpeg', this.imageQuality);
    
            const imageFile = this.dataURLtoFile(croppedDataUrl, 'image.jpg');
            this.init_image = imageFile;
    
            // API 호출 및 처리
            if (!this.UI.progress.includes('Game')) {
                if (this.UI.progress == 'Take') {
                    this.Game.stopLoop();
                    const _callback = () => this.UI.progress_GameVisible()
                    this.UI.progress_TakeImage('disappear', _callback)
                } else {
                    this.progress_Game('init');
                }
            } else {
                if (this.UI.progress == 'GameStart') {
                    this.Game.playLoop()
                }
            }
            // else if (this.UI.progress = 'GameStopped') {
                // this.progress_Game('continue');
            // }
            
    
            const response = await this.app.replicateImage(imageFile);
    
            if (response && response.data.new_image_url) {
                // console.log('응답 완료 ', this.UI.progress)
                this.resultImage = response.data.new_image_url;
                if (this.UI.progress == 'GameStart') {
                    // console.log('gameStart')
                    this.UI.progress_Notification_Complete_Replicate(this.resultImage);
                } else if (this.UI.progress == 'Game') {
                    this.finishGame()
                    this.UI.progress_GameVisible()
                }
            } else {
                // console.error('새 이미지 URL을 가져오지 못했습니다.');
                this.testError();
                this.buttonGenerate.forEach(button => button.disabled = false)
            }
        } catch (error) {
            // console.error('이미지 복제 중 오류 발생:', error);
            this.testError();
            this.buttonGenerate.forEach(button => button.disabled = false)
        } finally {
        }
    }

    async getFrames() {
        try {
            const response = await this.app.getCardFrames();
            if (response) {
                this.frames = response;
                // console.log('frame ' ,response)
            }
        } catch(error) {}
    }
    async initPost() {
        this.buttonPost.addEventListener('click', async () => {
            try {
                this.buttonPost.disabled = true;
                const newImage = document.createElement('div');
                newImage.classList.add('item-image')
                newImage.classList.add('item-result')
                newImage.classList.add('hidden')
                const _clone = document.querySelector('.swiper-slide-active .thumb').cloneNode(true);
            
                gsap.utils.toArray('img', _clone).forEach(c => {
                    c.crossOrigin = 'anonymous';
                })
                newImage.appendChild(_clone);

                document.body.appendChild(newImage)
                
                const dataUrl = await domToJpeg(newImage, { quality: 0.8 }); // 적절한 품질 값 설정
    
                // // DataURL을 File로 변환
                const imageFile = this.dataURLtoFile(dataUrl, 'new_image.jpg');
                this.generative_image = imageFile;
    
                // API 호출
                const response = await this.app.generateImage(this.init_image, this.generative_image);
    
                if (response && response.data.generative_image) {
                    this.userOutput = response.data.generative_image;
                    this.appearResult();
                } else {
                    // console.error('새 이미지 URL을 가져오지 못했습니다.');
                    this.testError()
                }
            } catch (error) {
                this.buttonPost.disabled = false;
                // console.error('이미지 생성 중 오류 발생:', error);
            } finally {
            }
        });
    }

    appearResult() {
        const layer = document.querySelector('.layer[data-type="Share"]');
        const image = document.createElement('img');
        image.src = this.userOutput;
        layer.querySelector('.item-image .thumb').appendChild(image);

        const _callback = () => {
            this.UI.progress_Share('appear')
        }
        this.UI.progress_SelectionFrame('disappear', _callback)
    }
    
    async initSave() {

    }

    async blobToData(blob) {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    }

    async inputImage(file) {
        if (!file) return;
        try {
            const resData = await this.blobToData(file);

            document.body.classList.add('has-file')

            let _isToResize = true;

            if (this.cropper) {
                this.cropper.destroy();
                _isToResize = false;
            }

            const newCropper = new CropImage(resData);
            this.cropper = newCropper;

            const _callback = () => {
                this.UI.progress_Crop('appear')
                if (_isToResize) {
                    this.cropper.updateSize()
                }
            }
            this.UI.progress_Onboard('disappear', _callback)
        } catch(error) {
            // 에러 발생 - alert
        }
    }


    async initInputGuide() {
        // console.log('가이드 : ', document.querySelector('#input-hidden-guide').checked)
        if (!document.querySelector('#input-hidden-guide').checked) {
            try {
                const response = await this.app.hiddenGuide();
                if (response) {
                    // console.log('가이드 안보기')
                }
            } catch(error) {
                // console.log('error')

            }
        }
    }

    initInputImage() {

        gsap.utils.toArray('.input-file-container').forEach(input => {
            const fileInput = input.querySelector('input');
            fileInput.addEventListener('change', (event) => {
                if (event.target.files.length > 0) {
                    this.inputImage(event.target.files[0])

                    this.initInputGuide()
                }
            });
        })
    
    }

    generateSelectFrame() {
        const layer = document.querySelector('.layer[data-type="SelectionFrame"]');
        const swiperContainer = document.createElement('div');
        swiperContainer.classList.add('swiper')

        swiperContainer.innerHTML = `
            <div class="swiper-wrapper">
                ${this.frames.map((frame, i) => `
                    <div class="swiper-slide item-image item-result">
                        <div class="thumb">
                            <div class="thumb-frame">
                                <img src="${frame.card_frame}" alt="frame-${frame.name}" />
                            </div>
                            <div class="thumb-result">
                                <img src="${this.resultImage}" alt="Slide Image" />
                            </div>
                        </div>
                    </div>`
                )
                .join('')}
            </div>
        `;

        const label = layer.querySelector('.info-label');
        const index = layer.querySelector('.info-indicator span');
        layer.querySelector('.swiper-container').appendChild(swiperContainer)
        label.innerHTML = this.frames[0].name;

        let swiper;
        const initSwiper = () => {
            swiper = new Swiper(swiperContainer, {
                // Default parameters
                slidesPerView: 'auto',
                spaceBetween: 18,
                centeredSlides: true,
                slidesOffsetBefore: 0,
                slidesOffsetAfter: 0,
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true
                },
                on: {
                    init: () => {
                        this.UI.appearSwiper();
                    },
                },
            });
            swiper.on('slideChange', (e) => {
                const { activeIndex, previousIndex } = e;

                // console.log('swiper change', e)

                label.innerHTML = this.frames[activeIndex].name;
                index.innerHTML = activeIndex + 1;
            })
        };

        const destroySwiper = () => {
            if (swiper) {
                swiper.destroy(true, true);
                swiper = undefined;
                gsap.utils.toArray(".swiper-slide", _swiper).forEach((s) => {
                    gsap.set(s, { clearProps: "all" });
                });
            }
        };
        // const handleResize = () => {
        //     if (window.innerWidth <= 768) {
        //         destroySwiper();
        //     } else {
        //         if (!swiper) {
        //             initSwiper();
        //         }
        //     }
        // };

        // window.addEventListener("resize", handleResize);
        initSwiper();

    }
}